"use strict";
import { usePendingActivity } from "components/AccountDrawer/MiniPortfolio/Activity/hooks";
import { AdaptiveTokenBalancesProvider } from "graphql/data/apollo/AdaptiveTokenBalancesProvider";
import { useAssetActivitySubscription } from "graphql/data/apollo/AssetActivityProvider";
import { useAccount } from "hooks/useAccount";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useActiveSmartPool } from "state/application/hooks";
import {
  SwapOrderStatus,
  usePortfolioBalancesLazyQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import {
  useEnabledChains,
  useHideSmallBalancesSetting,
  useHideSpamTokensSetting
} from "uniswap/src/features/settings/hooks";
import { SUBSCRIPTION_CHAINIDS } from "utilities/src/apollo/constants";
import { usePrevious } from "utilities/src/react/hooks";
function mayAffectTokenBalances(data) {
  if (data?.onAssetActivity?.details.__typename === "SwapOrderDetails" && data.onAssetActivity.details.orderStatus !== SwapOrderStatus.Filled) {
    return false;
  }
  return true;
}
function useIsRealtime() {
  const { chainId } = useAccount();
  const isRealtimeEnabled = useFeatureFlag(FeatureFlags.Realtime);
  return isRealtimeEnabled && chainId && SUBSCRIPTION_CHAINIDS.includes(chainId);
}
function useHasAccountUpdate() {
  const { pendingActivityCount } = usePendingActivity();
  const prevPendingActivityCount = usePrevious(pendingActivityCount);
  const hasLocalStateUpdate = !!prevPendingActivityCount && pendingActivityCount < prevPendingActivityCount;
  const isRealtime = useIsRealtime();
  const { data } = useAssetActivitySubscription();
  const prevData = usePrevious(data);
  const account = useAccount();
  const prevAccount = usePrevious(account.address);
  const { address: smartPool } = useActiveSmartPool();
  const prevSmartPool = usePrevious(smartPool);
  const { pathname: page } = useLocation();
  const prevPage = usePrevious(page);
  const { isTestnetModeEnabled } = useEnabledChains();
  const prevIsTestnetModeEnabled = usePrevious(isTestnetModeEnabled);
  return useMemo(() => {
    const hasPolledTxUpdate = !isRealtime && hasLocalStateUpdate;
    const hasSubscriptionTxUpdate = data !== prevData && mayAffectTokenBalances(data);
    const accountChanged = Boolean(prevAccount !== account.address && account.address);
    const hasTestnetModeChanged = prevIsTestnetModeEnabled !== isTestnetModeEnabled;
    const smartPoolChanged = Boolean(prevSmartPool !== smartPool && smartPool);
    const sendPageChanged = page !== prevPage && !!smartPool && (page === "/send" || prevPage === "/send");
    return hasPolledTxUpdate || hasSubscriptionTxUpdate || accountChanged || hasTestnetModeChanged || smartPoolChanged || sendPageChanged;
  }, [
    account.address,
    data,
    smartPool,
    page,
    hasLocalStateUpdate,
    isRealtime,
    prevAccount,
    prevData,
    prevSmartPool,
    prevPage,
    prevIsTestnetModeEnabled,
    isTestnetModeEnabled
  ]);
}
function usePortfolioValueModifiers() {
  const hideSmallBalances = useHideSmallBalancesSetting();
  const hideSpamTokens = useHideSpamTokensSetting();
  return useMemo(
    () => ({
      includeSmallBalances: !hideSmallBalances,
      includeSpamTokens: !hideSpamTokens
    }),
    [hideSmallBalances, hideSpamTokens]
  );
}
export function TokenBalancesProvider({ children }) {
  const [lazyFetch, query] = usePortfolioBalancesLazyQuery({ errorPolicy: "all" });
  const account = useAccount();
  const hasAccountUpdate = useHasAccountUpdate();
  const valueModifiers = usePortfolioValueModifiers();
  const prevValueModifiers = usePrevious(valueModifiers);
  const { gqlChains } = useEnabledChains();
  const { address: smartPoolAddress } = useActiveSmartPool();
  const { pathname: page } = useLocation();
  const isSendPage = page === "/send";
  const shouldQueryPoolBalances = smartPoolAddress && !isSendPage;
  const fetch = useCallback(() => {
    if (!account.address) {
      return;
    }
    lazyFetch({
      variables: {
        ownerAddress: shouldQueryPoolBalances ? smartPoolAddress : account.address,
        chains: gqlChains,
        valueModifiers: [
          {
            ownerAddress: account.address,
            includeSpamTokens: valueModifiers.includeSpamTokens,
            includeSmallBalances: valueModifiers.includeSmallBalances,
            tokenExcludeOverrides: [],
            tokenIncludeOverrides: []
          }
        ]
      }
    });
  }, [account.address, lazyFetch, smartPoolAddress, shouldQueryPoolBalances, valueModifiers, gqlChains]);
  return <AdaptiveTokenBalancesProvider
    query={query}
    fetch={fetch}
    stale={hasAccountUpdate || valueModifiers !== prevValueModifiers}
  >{children}</AdaptiveTokenBalancesProvider>;
}
