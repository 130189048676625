"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import {
  DEFAULT_POSITION_STATE,
  PositionFlowStep
} from "pages/Pool/Positions/create/types";
import React, { useContext } from "react";
import { PositionField } from "types/position";
export const CreatePositionContext = React.createContext({
  step: PositionFlowStep.SELECT_TOKENS_AND_FEE_TIER,
  setStep: () => void 0,
  positionState: DEFAULT_POSITION_STATE,
  setPositionState: () => void 0,
  feeTierSearchModalOpen: false,
  setFeeTierSearchModalOpen: () => void 0,
  createPoolInfoDismissed: false,
  setCreatePoolInfoDismissed: () => void 0,
  derivedPositionInfo: {
    protocolVersion: ProtocolVersion.V4,
    currencies: [void 0, void 0]
  }
});
export const useCreatePositionContext = () => {
  return useContext(CreatePositionContext);
};
export const DEFAULT_PRICE_RANGE_STATE = {
  priceInverted: false,
  fullRange: false,
  minPrice: void 0,
  maxPrice: void 0,
  initialPrice: "",
  initialPriceInverted: false
};
export const PriceRangeContext = React.createContext({
  priceRangeState: DEFAULT_PRICE_RANGE_STATE,
  setPriceRangeState: () => void 0,
  derivedPriceRangeInfo: {
    protocolVersion: ProtocolVersion.V4,
    isSorted: false,
    ticksAtLimit: [false, false],
    invertPrice: false,
    tickSpaceLimits: [0, 0],
    deposit0Disabled: false,
    deposit1Disabled: false,
    ticks: [0, 0],
    invalidPrice: false,
    invalidRange: false,
    outOfRange: false,
    prices: [void 0, void 0],
    pricesAtLimit: [void 0, void 0],
    pricesAtTicks: [void 0, void 0]
  }
});
export const usePriceRangeContext = () => {
  return useContext(PriceRangeContext);
};
export const DEFAULT_DEPOSIT_STATE = {
  exactField: PositionField.TOKEN0
};
export const DepositContext = React.createContext({
  depositState: DEFAULT_DEPOSIT_STATE,
  setDepositState: () => void 0,
  derivedDepositInfo: {}
});
export const useDepositContext = () => {
  return useContext(DepositContext);
};
export const CreateTxContext = React.createContext(void 0);
export const useCreateTxContext = () => {
  return useContext(CreateTxContext);
};
