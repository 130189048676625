"use strict";
import { CHAIN_IDS_TO_NAMES } from "constants/chains";
function getChainIdFromName(name) {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([, n]) => n === name);
  const chainId = entry?.[0];
  return chainId ? parseInt(chainId) : void 0;
}
export var ParsedChainIdKey = /* @__PURE__ */ ((ParsedChainIdKey2) => {
  ParsedChainIdKey2["INPUT"] = "input";
  ParsedChainIdKey2["OUTPUT"] = "output";
  return ParsedChainIdKey2;
})(ParsedChainIdKey || {});
export function getParsedChainId(parsedQs, key = "input" /* INPUT */) {
  const chain = key === "input" /* INPUT */ ? parsedQs?.chain : parsedQs?.outputChain;
  if (!chain || typeof chain !== "string") {
    return void 0;
  }
  return getChainIdFromName(chain);
}
