"use strict";
import { queryOptions } from "@tanstack/react-query";
import { Token } from "@uniswap/sdk-core";
import {
  AVERAGE_L1_BLOCK_TIME,
  BACKEND_SUPPORTED_CHAINS,
  CHAIN_NAME_TO_CHAIN_ID,
  UX_SUPPORTED_GQL_CHAINS,
  chainIdToBackendChain,
  getChainFromChainUrlParam,
  isSupportedChainId
} from "constants/chains";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import ms from "ms";
import { useEffect } from "react";
import { GQL_MAINNET_CHAINS, UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { WRAPPED_NATIVE_CURRENCY, nativeOnChain } from "uniswap/src/constants/tokens";
import {
  HistoryDuration,
  PriceSource,
  TokenStandard
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { UniverseChainId } from "uniswap/src/types/chains";
import { getNativeTokenDBAddress } from "utils/nativeTokens";
export var PollingInterval = ((PollingInterval2) => {
  PollingInterval2[PollingInterval2["Slow"] = ms(`5m`)] = "Slow";
  PollingInterval2[PollingInterval2["Normal"] = ms(`1m`)] = "Normal";
  PollingInterval2[PollingInterval2["Fast"] = AVERAGE_L1_BLOCK_TIME] = "Fast";
  PollingInterval2[PollingInterval2["LightningMcQueen"] = ms(`3s`)] = "LightningMcQueen";
  return PollingInterval2;
})(PollingInterval || {});
export function usePollQueryWhileMounted(queryResult, interval) {
  const { startPolling, stopPolling } = queryResult;
  useEffect(() => {
    startPolling(interval);
    return stopPolling;
  }, [interval, startPolling, stopPolling]);
  return queryResult;
}
export var TimePeriod = /* @__PURE__ */ ((TimePeriod2) => {
  TimePeriod2["HOUR"] = "H";
  TimePeriod2["DAY"] = "D";
  TimePeriod2["WEEK"] = "W";
  TimePeriod2["MONTH"] = "M";
  TimePeriod2["YEAR"] = "Y";
  return TimePeriod2;
})(TimePeriod || {});
export function toHistoryDuration(timePeriod) {
  switch (timePeriod) {
    case "H" /* HOUR */:
      return HistoryDuration.Hour;
    case "D" /* DAY */:
      return HistoryDuration.Day;
    case "W" /* WEEK */:
      return HistoryDuration.Week;
    case "M" /* MONTH */:
      return HistoryDuration.Month;
    case "Y" /* YEAR */:
      return HistoryDuration.Year;
  }
}
export function isPricePoint(p) {
  return p !== void 0;
}
export function isGqlSupportedChain(chainId) {
  return !!chainId && GQL_MAINNET_CHAINS.includes(UNIVERSE_CHAIN_INFO[chainId].backendChain.chain);
}
export function toContractInput(currency) {
  const chain = chainIdToBackendChain({ chainId: currency.chainId });
  return { chain, address: currency.isToken ? currency.address : getNativeTokenDBAddress(chain) };
}
export function gqlToCurrency(token) {
  if (!token.chain) {
    return void 0;
  }
  const chainId = getChainFromChainUrlParam(token.chain.toLowerCase())?.id;
  if (!chainId) {
    return void 0;
  }
  if (token.standard === TokenStandard.Native || token.address === NATIVE_CHAIN_ID || !token.address) {
    return nativeOnChain(chainId);
  } else {
    return new Token(
      chainId,
      token.address,
      token.decimals ?? 18,
      token.symbol ?? void 0,
      token.name ?? token.project?.name ?? void 0
    );
  }
}
export function fiatOnRampToCurrency(forCurrency) {
  if (!isSupportedChainId(Number(forCurrency.chainId))) {
    return void 0;
  }
  const supportedChainId = Number(forCurrency.chainId);
  if (!forCurrency.address) {
    return nativeOnChain(supportedChainId);
  } else {
    return new Token(supportedChainId, forCurrency.address, 18, forCurrency.cryptoCurrencyCode, forCurrency.displayName);
  }
}
export function getSupportedGraphQlChain(chain, options) {
  const fallbackChain = options?.fallbackToEthereum ? UNIVERSE_CHAIN_INFO[UniverseChainId.Mainnet] : void 0;
  return chain?.backendChain.backendSupported ? chain : fallbackChain;
}
export function isSupportedGQLChain(chain) {
  const chains = UX_SUPPORTED_GQL_CHAINS;
  return chains.includes(chain);
}
export function supportedChainIdFromGQLChain(chain) {
  return isSupportedGQLChain(chain) ? CHAIN_NAME_TO_CHAIN_ID[chain] : void 0;
}
export function isBackendSupportedChain(chain) {
  return BACKEND_SUPPORTED_CHAINS.includes(chain);
}
export function getTokenExploreURL({ tab, chain }) {
  const chainName = chain?.toLowerCase();
  return `/explore/${tab}${chain ? `/${chainName}` : ""}`;
}
export function getTokenDetailsURL({
  address,
  chain,
  inputAddress
}) {
  const chainName = chain.toLowerCase();
  const tokenAddress = address ?? NATIVE_CHAIN_ID;
  const inputAddressSuffix = inputAddress ? `?inputCurrency=${inputAddress}` : "";
  return `/explore/tokens/${chainName}/${tokenAddress}${inputAddressSuffix}`;
}
export function getPoolDetailsURL(address) {
  return `/smart-pool/${address}`;
}
export function unwrapToken(chainId, token) {
  if (!token?.address) {
    return token;
  }
  const address = token.address.toLowerCase();
  const nativeAddress = WRAPPED_NATIVE_CURRENCY[chainId]?.address.toLowerCase();
  if (address !== nativeAddress) {
    return token;
  }
  const nativeToken = nativeOnChain(chainId);
  return {
    ...token,
    ...nativeToken,
    project: {
      ...token.project,
      name: nativeToken.name
    },
    address: NATIVE_CHAIN_ID,
    extensions: void 0
    // prevents marking cross-chain wrapped tokens as native
  };
}
const PROTOCOL_META = {
  [PriceSource.SubgraphV2]: {
    name: "v2",
    color: "accent3",
    gradient: { start: "rgba(96, 123, 238, 0.20)", end: "rgba(55, 70, 136, 0.00)" }
  },
  [PriceSource.SubgraphV3]: {
    name: "v3",
    color: "accent1",
    gradient: { start: "rgba(252, 116, 254, 0.20)", end: "rgba(252, 116, 254, 0.00)" }
  },
  [PriceSource.SubgraphV4]: {
    name: "v4",
    color: "accent1",
    // TODO(WEB-4618): update the colors when they are available
    gradient: { start: "rgba(252, 116, 254, 0.20)", end: "rgba(252, 116, 254, 0.00)" }
  }
  /* [PriceSource.UniswapX]: { name: 'UniswapX', color: purple } */
};
export function getProtocolColor(priceSource, theme) {
  return theme[PROTOCOL_META[priceSource].color];
}
export function getProtocolName(priceSource) {
  return PROTOCOL_META[priceSource].name;
}
export function getProtocolGradient(priceSource) {
  return PROTOCOL_META[priceSource].gradient;
}
export var OrderDirection = /* @__PURE__ */ ((OrderDirection2) => {
  OrderDirection2["Asc"] = "asc";
  OrderDirection2["Desc"] = "desc";
  return OrderDirection2;
})(OrderDirection || {});
export function apolloQueryOptions(options) {
  return queryOptions({
    ...options,
    staleTime: 0
  });
}
