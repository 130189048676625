"use strict";
import { DEFAULT_LOCALE, Locale } from "uniswap/src/features/language/constants";
export default function formatLocaleNumber({
  number,
  locale,
  sigFigs,
  fixedDecimals,
  options = {}
}) {
  let localeArg;
  const isSupportedLocale = locale && Object.values(Locale).includes(locale);
  if (!isSupportedLocale) {
    localeArg = DEFAULT_LOCALE;
  } else {
    localeArg = [locale, DEFAULT_LOCALE];
  }
  options.minimumFractionDigits = options.minimumFractionDigits || fixedDecimals;
  options.maximumFractionDigits = options.maximumFractionDigits || fixedDecimals;
  options.maximumSignificantDigits = options.maximumSignificantDigits || fixedDecimals ? void 0 : sigFigs;
  let numberString;
  if (typeof number === "number") {
    numberString = fixedDecimals ? parseFloat(number.toFixed(fixedDecimals)) : number;
  } else {
    const baseString = parseFloat(number.toSignificant(sigFigs));
    numberString = fixedDecimals ? parseFloat(baseString.toFixed(fixedDecimals)) : baseString;
  }
  return numberString.toLocaleString(localeArg, options);
}
