"use strict";
import { useCurrencyInfo } from "hooks/Tokens";
import { GRG } from "uniswap/src/constants/tokens";
import { SafetyLevel } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { Trans, t } from "uniswap/src/i18n";
export const TOKEN_SAFETY_ARTICLE = "https://support.uniswap.org/hc/en-us/articles/8723118437133";
const SafetyLevelWeight = {
  [SafetyLevel.Blocked]: 4,
  [SafetyLevel.StrongWarning]: 3,
  [SafetyLevel.MediumWarning]: 2,
  [SafetyLevel.Verified]: 1
};
export function getPriorityWarning(token0Warning, token1Warning) {
  if (token0Warning && token1Warning) {
    if (SafetyLevelWeight[token1Warning.level] > SafetyLevelWeight[token0Warning.level]) {
      return token1Warning;
    }
    return token0Warning;
  }
  return token0Warning ?? token1Warning;
}
export function getWarningCopy(warning, plural = false, tokenSymbol) {
  let heading = null, description = null;
  if (warning) {
    switch (warning.level) {
      case SafetyLevel.MediumWarning:
        heading = tokenSymbol ? t("token.safety.warning.medium.heading.named", {
          tokenSymbol
        }) : t("token.safety.warning.medium.heading.default", { count: plural ? 2 : 1 });
        description = t("token.safety.warning.doYourOwnResearch");
        break;
      case SafetyLevel.StrongWarning:
        heading = tokenSymbol ? t("token.safety.warning.strong.heading.named", {
          tokenSymbol
        }) : t("token.safety.warning.strong.heading.default", { count: plural ? 2 : 1 });
        description = t("token.safety.warning.doYourOwnResearch");
        break;
      case SafetyLevel.Blocked:
        description = tokenSymbol ? t(`token.safety.warning.blocked.description.named`, {
          tokenSymbol
        }) : t("token.safety.warning.blocked.description.default", { count: plural ? 2 : 1 });
        break;
    }
  }
  return { heading, description };
}
export const MediumWarning = {
  level: SafetyLevel.MediumWarning,
  message: <Trans i18nKey="token.safetyLevel.medium.header" />,
  canProceed: true
};
export const StrongWarning = {
  level: SafetyLevel.StrongWarning,
  message: <Trans i18nKey="token.safetyLevel.strong.header" />,
  canProceed: true
};
export const BlockedWarning = {
  level: SafetyLevel.Blocked,
  message: <Trans i18nKey="token.safetyLevel.blocked.header" />,
  canProceed: false
};
export function useTokenWarning(tokenAddress, chainId) {
  const currencyInfo = useCurrencyInfo(tokenAddress, chainId);
  if (chainId && GRG[chainId].address.toLowerCase() === tokenAddress?.toLowerCase()) {
    return void 0;
  }
  switch (currencyInfo?.safetyLevel) {
    case SafetyLevel.MediumWarning:
      return MediumWarning;
    case SafetyLevel.StrongWarning:
      return StrongWarning;
    case SafetyLevel.Blocked:
      return BlockedWarning;
    default:
      return void 0;
  }
}
export function displayWarningLabel(warning) {
  return warning && warning.level !== SafetyLevel.MediumWarning;
}
