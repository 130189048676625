import { RemoveScroll } from "@tamagui/remove-scroll";
import { useCallback, useState } from "react";
import { Adapt, Dialog, Sheet, VisuallyHidden, styled, useIsTouchDevice } from "tamagui";
import { Flex } from "ui/src/components/layout";
import { zIndices } from "ui/src/theme";
import { useShadowPropsShort } from "ui/src/theme/shadows";
export function WebBottomSheet({ isOpen, onClose, children, ...rest }) {
  const isTouchDevice = useIsTouchDevice();
  const [isHandlePressed, setHandlePressed] = useState(false);
  const handleClose = useCallback(
    (open) => {
      if (!open && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  const sheetOverrideStyles = {
    ...rest,
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%"
  };
  return <RemoveScroll enabled={isOpen}><Sheet
    dismissOnOverlayPress
    dismissOnSnapToBottom
    modal
    animation="200ms"
    disableDrag={isTouchDevice && !isHandlePressed}
    open={isOpen}
    snapPointsMode="fit"
    zIndex={zIndices.modal}
    onOpenChange={handleClose}
  >
    <Sheet.Frame
      borderBottomWidth="$none"
      borderColor="$surface3"
      borderTopLeftRadius="$rounded16"
      borderTopRightRadius="$rounded16"
      borderWidth="$spacing1"
      flex={1}
      height={rest.$sm?.height}
      maxHeight={rest.$sm?.maxHeight ?? "100dvh"}
      px="$spacing8"
      zIndex={zIndices.modal}
      {...sheetOverrideStyles}
    >
      <Sheet.Handle
        justifyContent="center"
        m={0}
        pb="$spacing16"
        pt="$spacing8"
        width="100%"
        backgroundColor="$transparent"
        onMouseDown={() => setHandlePressed(true)}
        onMouseUp={() => setHandlePressed(false)}
      ><Flex backgroundColor="$surface3" height="$spacing4" width="$spacing32" /></Sheet.Handle>
      {children}
    </Sheet.Frame>
    <Sheet.Overlay
      animation="lazy"
      backgroundColor="$scrim"
      enterStyle={{ opacity: 0 }}
      exitStyle={{ opacity: 0 }}
      zIndex={zIndices.modalBackdrop}
    />
  </Sheet></RemoveScroll>;
}
const Overlay = styled(Dialog.Overlay, {
  animation: "300ms",
  backgroundColor: "$scrim",
  opacity: 0.5,
  enterStyle: { opacity: 0 },
  exitStyle: { opacity: 0 }
});
export function AdaptiveWebModal({
  isOpen,
  onClose,
  children,
  adaptToSheet = true,
  style,
  alignment = "center",
  ...rest
}) {
  const filteredRest = Object.fromEntries(Object.entries(rest).filter(([_, v]) => v !== void 0));
  const handleClose = useCallback(
    (open) => {
      if (!open && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  const isTopAligned = alignment === "top";
  return <Dialog modal open={isOpen} onOpenChange={handleClose}>
    <VisuallyHidden><Dialog.Title /></VisuallyHidden>
    {adaptToSheet && !isTopAligned && // Tamagui Sheets always animate in from the bottom, so we cannot use Sheets on top aligned modals
    <Adapt when="sm"><WebBottomSheet isOpen={isOpen} style={style} onClose={onClose} {...filteredRest}><Adapt.Contents /></WebBottomSheet></Adapt>}
    <Dialog.Portal zIndex={zIndices.modal}>
      <Overlay key="overlay" zIndex={zIndices.modalBackdrop} />
      <Dialog.Content
        key="content"
        bordered
        elevate
        animateOnly={["transform", "opacity"]}
        animation={isOpen ? "fastHeavy" : "fastExitHeavy"}
        borderColor="$surface3"
        borderRadius="$rounded16"
        enterStyle={{ x: 0, y: isTopAligned ? -20 : 20, opacity: 0 }}
        exitStyle={{ x: 0, y: isTopAligned ? -20 : 10, opacity: 0 }}
        gap={4}
        m="$spacing16"
        maxHeight="calc(100vh - 32px)"
        maxWidth={420}
        overflow="hidden"
        px="$spacing24"
        py="$spacing16"
        style={style}
        width="calc(100vw - 32px)"
        zIndex={zIndices.modal}
        {...filteredRest}
      >{children}</Dialog.Content>
    </Dialog.Portal>
  </Dialog>;
}
export function WebModalWithBottomAttachment({
  isOpen,
  onClose,
  children,
  adaptToSheet = true,
  style,
  alignment = "center",
  bottomAttachment,
  backgroundColor = "$surface1",
  ...rest
}) {
  const shadowProps = useShadowPropsShort();
  const filteredRest = Object.fromEntries(Object.entries(rest).filter(([_, v]) => v !== void 0));
  const handleClose = useCallback(
    (open) => {
      if (!open && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  const isTopAligned = alignment === "top";
  return <Dialog modal open={isOpen} onOpenChange={handleClose}>
    <VisuallyHidden><Dialog.Title /></VisuallyHidden>
    {adaptToSheet && !isTopAligned && // Tamagui Sheets always animate in from the bottom, so we cannot use Sheets on top aligned modals
    <Adapt when="sm"><WebBottomSheet isOpen={isOpen} style={style} onClose={onClose} {...filteredRest}><Adapt.Contents /></WebBottomSheet></Adapt>}
    <Dialog.Portal zIndex={zIndices.modal}>
      <Overlay key="overlay" zIndex={zIndices.modalBackdrop} />
      <Dialog.Content
        key="content"
        unstyled
        animateOnly={["transform", "opacity"]}
        animation={isOpen ? "fastHeavy" : "fastExitHeavy"}
        backgroundColor="$transparent"
        enterStyle={{ x: 0, y: isTopAligned ? -20 : 20, opacity: 0 }}
        exitStyle={{ x: 0, y: isTopAligned ? -20 : 10, opacity: 0 }}
        maxHeight="calc(100vh - 32px)"
        maxWidth={420}
        overflow="hidden"
        p="$none"
        style={style}
        width="calc(100vw - 32px)"
        zIndex={zIndices.modal}
      ><Flex height="100%" width="100%" gap="$spacing8">
        <Flex
          {...shadowProps}
          backgroundColor={backgroundColor}
          borderColor="$surface3"
          borderRadius="$rounded16"
          borderWidth="$spacing1"
          px="$spacing24"
          py="$spacing16"
          gap="$spacing4"
          overflow="hidden"
          {...filteredRest}
        >{children}</Flex>
        {bottomAttachment && <Flex>{bottomAttachment}</Flex>}
      </Flex></Dialog.Content>
    </Dialog.Portal>
  </Dialog>;
}
