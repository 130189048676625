"use strict";
import { DEFAULT_TXN_DISMISS_MS } from "constants/misc";
import { useCallback, useMemo } from "react";
import {
  ApplicationModal,
  addPopup,
  addSuppressedPopups,
  removePopup,
  removeSuppressedPopups,
  setCloseModal,
  setOpenModal,
  setSmartPoolValue
} from "state/application/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
export function useModalIsOpen(modal) {
  const openModal = useAppSelector((state) => state.application.openModal?.name);
  return openModal === modal;
}
export function useToggleModal(modal) {
  const isOpen = useModalIsOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(() => {
    if (isOpen) {
      dispatch(setCloseModal(modal));
    } else {
      dispatch(setOpenModal({ name: modal }));
    }
  }, [dispatch, modal, isOpen]);
}
export function useCloseModal(modal) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setCloseModal(modal)), [dispatch, modal]);
}
export function useOpenModal(modal) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
}
export function useToggleSettingsMenu() {
  return useToggleModal(ApplicationModal.SETTINGS);
}
export function useShowClaimPopup() {
  return useModalIsOpen(ApplicationModal.CLAIM_POPUP);
}
export function useToggleShowClaimPopup() {
  return useToggleModal(ApplicationModal.CLAIM_POPUP);
}
export function useToggleSelfClaimModal() {
  return useToggleModal(ApplicationModal.SELF_CLAIM);
}
export function useToggleDelegateModal() {
  return useToggleModal(ApplicationModal.DELEGATE);
}
export function useToggleCreateModal() {
  return useToggleModal(ApplicationModal.CREATE);
}
export function useToggleVoteModal() {
  return useToggleModal(ApplicationModal.VOTE);
}
export function useToggleQueueModal() {
  return useToggleModal(ApplicationModal.QUEUE);
}
export function useToggleExecuteModal() {
  return useToggleModal(ApplicationModal.EXECUTE);
}
export function useTogglePrivacyPolicy() {
  return useToggleModal(ApplicationModal.PRIVACY_POLICY);
}
export function useAddPopup() {
  const dispatch = useAppDispatch();
  return useCallback(
    (content, key, removeAfterMs) => {
      dispatch(addPopup({ content, key, removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS }));
    },
    [dispatch]
  );
}
export function useRemovePopup() {
  const dispatch = useAppDispatch();
  return useCallback(
    (key) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}
export function useSelectActiveSmartPool() {
  const dispatch = useAppDispatch();
  return useCallback(
    (smartPoolValue) => {
      dispatch(
        setSmartPoolValue({
          smartPool: {
            address: smartPoolValue?.isToken ? smartPoolValue.address : void 0,
            name: smartPoolValue?.isToken && smartPoolValue.name ? smartPoolValue.name : void 0
          }
        })
      );
    },
    [dispatch]
  );
}
export function useActivePopups() {
  const list = useAppSelector((state) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
export function useSuppressPopups(popupTypes) {
  const dispatch = useAppDispatch();
  const suppressPopups = useCallback(() => dispatch(addSuppressedPopups({ popupTypes })), [dispatch, popupTypes]);
  const unsuppressPopups = useCallback(() => dispatch(removeSuppressedPopups({ popupTypes })), [dispatch, popupTypes]);
  return {
    suppressPopups,
    unsuppressPopups
  };
}
export function useActiveSmartPool() {
  return useAppSelector((state) => state.application.smartPool);
}
