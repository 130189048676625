"use strict";
import { CurrencyAmount, Percent } from "@uniswap/sdk-core";
import { Position } from "@uniswap/v3-sdk";
import { ConnectWalletButtonText } from "components/NavBar/accountCTAsExperimentUtils";
import { useToken } from "hooks/Tokens";
import { useAccount } from "hooks/useAccount";
import { usePool } from "hooks/usePools";
import { useV3PositionFees } from "hooks/useV3PositionFees";
import { useCallback, useMemo } from "react";
import { selectPercent } from "state/burn/v3/actions";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { Trans } from "uniswap/src/i18n";
import { unwrappedToken } from "utils/unwrappedToken";
export function useBurnV3State() {
  return useAppSelector((state) => state.burnV3);
}
export function useDerivedV3BurnInfo(position, asWETH = false) {
  const account = useAccount();
  const { percent } = useBurnV3State();
  const token0 = useToken(position?.token0);
  const token1 = useToken(position?.token1);
  const [, pool] = usePool(token0 ?? void 0, token1 ?? void 0, position?.fee);
  const positionSDK = useMemo(
    () => pool && position?.liquidity && typeof position?.tickLower === "number" && typeof position?.tickUpper === "number" ? new Position({
      pool,
      liquidity: position.liquidity.toString(),
      tickLower: position.tickLower,
      tickUpper: position.tickUpper
    }) : void 0,
    [pool, position]
  );
  const liquidityPercentage = new Percent(percent, 100);
  const discountedAmount0 = positionSDK ? liquidityPercentage.multiply(positionSDK.amount0.quotient).quotient : void 0;
  const discountedAmount1 = positionSDK ? liquidityPercentage.multiply(positionSDK.amount1.quotient).quotient : void 0;
  const liquidityValue0 = token0 && discountedAmount0 ? CurrencyAmount.fromRawAmount(asWETH ? token0 : unwrappedToken(token0), discountedAmount0) : void 0;
  const liquidityValue1 = token1 && discountedAmount1 ? CurrencyAmount.fromRawAmount(asWETH ? token1 : unwrappedToken(token1), discountedAmount1) : void 0;
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? void 0, position?.tokenId, asWETH);
  const outOfRange = pool && position ? pool.tickCurrent < position.tickLower || pool.tickCurrent > position.tickUpper : false;
  let error;
  if (!account.isConnected) {
    error = <ConnectWalletButtonText />;
  }
  if (percent === 0) {
    error = error ?? <Trans i18nKey="burn.input.enterAPercent.error" />;
  }
  return {
    position: positionSDK,
    liquidityPercentage,
    liquidityValue0,
    liquidityValue1,
    feeValue0,
    feeValue1,
    outOfRange,
    error
  };
}
export function useBurnV3ActionHandlers() {
  const dispatch = useAppDispatch();
  const onPercentSelect = useCallback(
    (percent) => {
      dispatch(selectPercent({ percent }));
    },
    [dispatch]
  );
  return {
    onPercentSelect
  };
}
