"use strict";
import { Fraction } from "@uniswap/sdk-core";
import JSBI from "jsbi";
import formatLocaleNumber from "lib/utils/formatLocaleNumber";
import { DEFAULT_LOCALE } from "uniswap/src/features/language/constants";
export function formatCurrencyAmount(amount, sigFigs, locale = DEFAULT_LOCALE, fixedDecimals) {
  if (!amount) {
    return "-";
  }
  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return "0";
  }
  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 1e5))) {
    return `<${formatLocaleNumber({ number: 1e-5, locale })}`;
  }
  return formatLocaleNumber({ number: amount, locale, sigFigs, fixedDecimals });
}
