"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
import JSBI from "jsbi";
const MIN_NATIVE_CURRENCY_FOR_GAS = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16));
export function maxAmountSpend(currencyAmount, isSmartPool) {
  if (!currencyAmount) {
    return void 0;
  }
  if (currencyAmount.currency.isNative && !isSmartPool) {
    if (JSBI.greaterThan(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)) {
      return CurrencyAmount.fromRawAmount(
        currencyAmount.currency,
        JSBI.subtract(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)
      );
    } else {
      return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0));
    }
  }
  return currencyAmount;
}
