"use strict";
import { CHAIN_IDS_TO_NAMES } from "constants/chains";
import AppJsonRpcProvider from "rpc/AppJsonRpcProvider";
import ConfiguredJsonRpcProvider from "rpc/ConfiguredJsonRpcProvider";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { SUPPORTED_CHAIN_IDS } from "uniswap/src/types/chains";
function getAppProvider(chainId) {
  const info = UNIVERSE_CHAIN_INFO[chainId];
  return new AppJsonRpcProvider(
    info.rpcUrls.appOnly.http.map(
      (url) => {
        return new ConfiguredJsonRpcProvider(
          url,
          { chainId, name: CHAIN_IDS_TO_NAMES[chainId] }
        );
      }
    )
  );
}
export const RPC_PROVIDERS = Object.fromEntries(
  SUPPORTED_CHAIN_IDS.map((chain) => [chain, getAppProvider(chain)])
);
export function getBackupRpcProvider(chainId) {
  const url = "https://api.rigoblock.com/logs";
  return new AppJsonRpcProvider([new ConfiguredJsonRpcProvider(url, { chainId, name: CHAIN_IDS_TO_NAMES[chainId] })]);
}
