"use strict";
import { CurrencyAmount, Price, Rounding } from "@uniswap/sdk-core";
import {
  Pool,
  Position,
  TICK_SPACINGS,
  TickMath,
  encodeSqrtRatioX96,
  nearestUsableTick,
  priceToClosestTick,
  tickToPrice
} from "@uniswap/v3-sdk";
import { ConnectWalletButtonText } from "components/NavBar/accountCTAsExperimentUtils";
import { BIG_INT_ZERO } from "constants/misc";
import { useAccount } from "hooks/useAccount";
import { PoolState, usePool } from "hooks/usePools";
import { useSwapTaxes } from "hooks/useSwapTaxes";
import JSBI from "jsbi";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useActiveSmartPool } from "state/application/hooks";
import { useCurrencyBalances } from "state/connection/hooks";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  Bound,
  Field,
  setFullRange,
  typeInput,
  typeLeftRangeInput,
  typeRightRangeInput,
  typeStartPriceInput
} from "state/mint/v3/actions";
import { tryParseTick } from "state/mint/v3/utils";
import { Trans } from "uniswap/src/i18n";
import { getTickToPrice } from "utils/getTickToPrice";
export function useV3MintState() {
  return useAppSelector((state) => state.mintV3);
}
export function useV3MintActionHandlers(noLiquidity) {
  const dispatch = useAppDispatch();
  const onFieldAInput = useCallback(
    (typedValue) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }));
    },
    [dispatch, noLiquidity]
  );
  const onFieldBInput = useCallback(
    (typedValue) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }));
    },
    [dispatch, noLiquidity]
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const onLeftRangeInput = useCallback(
    (typedValue) => {
      dispatch(typeLeftRangeInput({ typedValue }));
      const paramMinPrice = searchParams.get("minPrice");
      if (!paramMinPrice || paramMinPrice && paramMinPrice !== typedValue) {
        searchParams.set("minPrice", typedValue);
        setSearchParams(searchParams);
      }
    },
    [dispatch, searchParams, setSearchParams]
  );
  const onRightRangeInput = useCallback(
    (typedValue) => {
      dispatch(typeRightRangeInput({ typedValue }));
      const paramMaxPrice = searchParams.get("maxPrice");
      if (!paramMaxPrice || paramMaxPrice && paramMaxPrice !== typedValue) {
        searchParams.set("maxPrice", typedValue);
        setSearchParams(searchParams);
      }
    },
    [dispatch, searchParams, setSearchParams]
  );
  const onStartPriceInput = useCallback(
    (typedValue) => {
      dispatch(typeStartPriceInput({ typedValue }));
    },
    [dispatch]
  );
  return {
    onFieldAInput,
    onFieldBInput,
    onLeftRangeInput,
    onRightRangeInput,
    onStartPriceInput
  };
}
export function useV3DerivedMintInfo(currencyA, currencyB, feeAmount, baseCurrency, existingPosition) {
  const account = useAccount();
  const { independentField, typedValue, leftRangeTypedValue, rightRangeTypedValue, startPriceTypedValue } = useV3MintState();
  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A;
  const currencies = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA,
      [Field.CURRENCY_B]: currencyB
    }),
    [currencyA, currencyB]
  );
  const [tokenA, tokenB, baseToken] = useMemo(
    () => [currencyA?.wrapped, currencyB?.wrapped, baseCurrency?.wrapped],
    [currencyA, currencyB, baseCurrency]
  );
  const [token0, token1] = useMemo(
    () => tokenA && tokenB ? tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] : [void 0, void 0],
    [tokenA, tokenB]
  );
  const { address: smartPoolAddress } = useActiveSmartPool();
  const balances = useCurrencyBalances(
    smartPoolAddress ?? void 0,
    useMemo(() => [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]], [currencies])
  );
  const currencyBalances = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1]
  };
  const [poolState, pool] = usePool(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B], feeAmount);
  const noLiquidity = poolState === PoolState.NOT_EXISTS;
  const invertPrice = Boolean(baseToken && token0 && !baseToken.equals(token0));
  const price = useMemo(() => {
    if (noLiquidity) {
      const parsedQuoteAmount = tryParseCurrencyAmount(startPriceTypedValue, invertPrice ? token0 : token1);
      if (parsedQuoteAmount && token0 && token1) {
        const baseAmount = tryParseCurrencyAmount("1", invertPrice ? token1 : token0);
        const price2 = baseAmount && parsedQuoteAmount ? new Price(
          baseAmount.currency,
          parsedQuoteAmount.currency,
          baseAmount.quotient,
          parsedQuoteAmount.quotient
        ) : void 0;
        return (invertPrice ? price2?.invert() : price2) ?? void 0;
      }
      return void 0;
    } else {
      return pool && token0 ? pool.priceOf(token0) : void 0;
    }
  }, [noLiquidity, startPriceTypedValue, invertPrice, token1, token0, pool]);
  const invalidPrice = useMemo(() => {
    const sqrtRatioX96 = price ? encodeSqrtRatioX96(price.numerator, price.denominator) : void 0;
    return price && sqrtRatioX96 && !(JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) && JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO));
  }, [price]);
  const mockPool = useMemo(() => {
    if (tokenA && tokenB && feeAmount && price && !invalidPrice) {
      const currentTick = priceToClosestTick(price);
      const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
      return new Pool(tokenA, tokenB, feeAmount, currentSqrt, JSBI.BigInt(0), currentTick, []);
    } else {
      return void 0;
    }
  }, [feeAmount, invalidPrice, price, tokenA, tokenB]);
  const poolForPosition = pool ?? mockPool;
  const tickSpaceLimits = useMemo(
    () => ({
      [Bound.LOWER]: feeAmount ? nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeAmount]) : void 0,
      [Bound.UPPER]: feeAmount ? nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeAmount]) : void 0
    }),
    [feeAmount]
  );
  const ticks = useMemo(() => {
    return {
      [Bound.LOWER]: typeof existingPosition?.tickLower === "number" ? existingPosition.tickLower : invertPrice && typeof rightRangeTypedValue === "boolean" || !invertPrice && typeof leftRangeTypedValue === "boolean" ? tickSpaceLimits[Bound.LOWER] : invertPrice ? tryParseTick(token1, token0, feeAmount, rightRangeTypedValue.toString()) : tryParseTick(token0, token1, feeAmount, leftRangeTypedValue.toString()),
      [Bound.UPPER]: typeof existingPosition?.tickUpper === "number" ? existingPosition.tickUpper : !invertPrice && typeof rightRangeTypedValue === "boolean" || invertPrice && typeof leftRangeTypedValue === "boolean" ? tickSpaceLimits[Bound.UPPER] : invertPrice ? tryParseTick(token1, token0, feeAmount, leftRangeTypedValue.toString()) : tryParseTick(token0, token1, feeAmount, rightRangeTypedValue.toString())
    };
  }, [
    existingPosition,
    feeAmount,
    invertPrice,
    leftRangeTypedValue,
    rightRangeTypedValue,
    token0,
    token1,
    tickSpaceLimits
  ]);
  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks || {};
  const ticksAtLimit = useMemo(
    () => ({
      [Bound.LOWER]: feeAmount && tickLower === tickSpaceLimits.LOWER,
      [Bound.UPPER]: feeAmount && tickUpper === tickSpaceLimits.UPPER
    }),
    [tickSpaceLimits, tickLower, tickUpper, feeAmount]
  );
  const invalidRange = Boolean(typeof tickLower === "number" && typeof tickUpper === "number" && tickLower >= tickUpper);
  const pricesAtLimit = useMemo(() => {
    return {
      [Bound.LOWER]: getTickToPrice(token0, token1, tickSpaceLimits.LOWER),
      [Bound.UPPER]: getTickToPrice(token0, token1, tickSpaceLimits.UPPER)
    };
  }, [token0, token1, tickSpaceLimits.LOWER, tickSpaceLimits.UPPER]);
  const pricesAtTicks = useMemo(() => {
    return {
      [Bound.LOWER]: getTickToPrice(token0, token1, ticks[Bound.LOWER]),
      [Bound.UPPER]: getTickToPrice(token0, token1, ticks[Bound.UPPER])
    };
  }, [token0, token1, ticks]);
  const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks;
  const outOfRange = Boolean(
    !invalidRange && price && lowerPrice && upperPrice && (price.lessThan(lowerPrice) || price.greaterThan(upperPrice))
  );
  const independentAmount = tryParseCurrencyAmount(
    typedValue,
    currencies[independentField]
  );
  const dependentAmount = useMemo(() => {
    const wrappedIndependentAmount = independentAmount?.wrapped;
    const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA;
    if (independentAmount && wrappedIndependentAmount && typeof tickLower === "number" && typeof tickUpper === "number" && poolForPosition) {
      if (outOfRange || invalidRange) {
        return void 0;
      }
      const position2 = wrappedIndependentAmount.currency.equals(poolForPosition.token0) ? Position.fromAmount0({
        pool: poolForPosition,
        tickLower,
        tickUpper,
        amount0: independentAmount.quotient,
        useFullPrecision: true
        // we want full precision for the theoretical position
      }) : Position.fromAmount1({
        pool: poolForPosition,
        tickLower,
        tickUpper,
        amount1: independentAmount.quotient
      });
      const dependentTokenAmount = wrappedIndependentAmount.currency.equals(poolForPosition.token0) ? position2.amount1 : position2.amount0;
      return dependentCurrency && CurrencyAmount.fromRawAmount(dependentCurrency, dependentTokenAmount.quotient);
    }
    return void 0;
  }, [
    independentAmount,
    outOfRange,
    dependentField,
    currencyB,
    currencyA,
    tickLower,
    tickUpper,
    poolForPosition,
    invalidRange
  ]);
  const parsedAmounts = useMemo(() => {
    return {
      [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
      [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount
    };
  }, [dependentAmount, independentAmount, independentField]);
  const deposit0Disabled = Boolean(
    typeof tickUpper === "number" && poolForPosition && poolForPosition.tickCurrent >= tickUpper
  );
  const deposit1Disabled = Boolean(
    typeof tickLower === "number" && poolForPosition && poolForPosition.tickCurrent <= tickLower
  );
  const depositADisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && tokenA && poolForPosition.token0.equals(tokenA) || deposit1Disabled && poolForPosition && tokenA && poolForPosition.token1.equals(tokenA)
  );
  const depositBDisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && tokenB && poolForPosition.token0.equals(tokenB) || deposit1Disabled && poolForPosition && tokenB && poolForPosition.token1.equals(tokenB)
  );
  const { inputTax: currencyATax, outputTax: currencyBTax } = useSwapTaxes(
    currencyA?.isToken ? currencyA.address : void 0,
    currencyB?.isToken ? currencyB.address : void 0,
    account.chainId
  );
  const position = useMemo(() => {
    if (!poolForPosition || !tokenA || !tokenB || typeof tickLower !== "number" || typeof tickUpper !== "number" || invalidRange) {
      return void 0;
    }
    const amount0 = !deposit0Disabled ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_A : Field.CURRENCY_B]?.quotient : BIG_INT_ZERO;
    const amount1 = !deposit1Disabled ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_B : Field.CURRENCY_A]?.quotient : BIG_INT_ZERO;
    if (amount0 !== void 0 && amount1 !== void 0) {
      return Position.fromAmounts({
        pool: poolForPosition,
        tickLower,
        tickUpper,
        amount0,
        amount1,
        useFullPrecision: true
        // we want full precision for the theoretical position
      });
    } else {
      return void 0;
    }
  }, [
    parsedAmounts,
    poolForPosition,
    tokenA,
    tokenB,
    deposit0Disabled,
    deposit1Disabled,
    invalidRange,
    tickLower,
    tickUpper
  ]);
  let errorMessage;
  if (!account.isConnected) {
    errorMessage = <ConnectWalletButtonText />;
  }
  if (poolState === PoolState.INVALID) {
    errorMessage = errorMessage ?? <Trans i18nKey="common.invalidPair" />;
  }
  if (invalidPrice) {
    errorMessage = errorMessage ?? <Trans i18nKey="mint.v3.input.invalidPrice.error" />;
  }
  if (!parsedAmounts[Field.CURRENCY_A] && !depositADisabled || !parsedAmounts[Field.CURRENCY_B] && !depositBDisabled) {
    errorMessage = errorMessage ?? <Trans i18nKey="common.noAmount.error" />;
  }
  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts;
  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    errorMessage = <Trans
      i18nKey="common.insufficientTokenBalance.error"
      values={{
        tokenSymbol: currencies[Field.CURRENCY_A]?.symbol
      }}
    />;
  }
  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    errorMessage = <Trans
      i18nKey="common.insufficientTokenBalance.error"
      values={{
        tokenSymbol: currencies[Field.CURRENCY_B]?.symbol
      }}
    />;
  }
  const isTaxed = currencyATax.greaterThan(0) || currencyBTax.greaterThan(0);
  const invalidPool = poolState === PoolState.INVALID || isTaxed;
  return {
    dependentField,
    currencies,
    pool,
    poolState,
    currencyBalances,
    parsedAmounts,
    ticks,
    price,
    pricesAtTicks,
    pricesAtLimit,
    position,
    noLiquidity,
    errorMessage,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
    isTaxed
  };
}
export function useRangeHopCallbacks(baseCurrency, quoteCurrency, feeAmount, tickLower, tickUpper, pool) {
  const dispatch = useAppDispatch();
  const baseToken = useMemo(() => baseCurrency?.wrapped, [baseCurrency]);
  const quoteToken = useMemo(() => quoteCurrency?.wrapped, [quoteCurrency]);
  const getDecrementLower = useCallback(() => {
    if (baseToken && quoteToken && typeof tickLower === "number" && feeAmount) {
      const newPrice = tickToPrice(baseToken, quoteToken, tickLower - TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    if (!(typeof tickLower === "number") && baseToken && quoteToken && feeAmount && pool) {
      const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    return "";
  }, [baseToken, quoteToken, tickLower, feeAmount, pool]);
  const getIncrementLower = useCallback(() => {
    if (baseToken && quoteToken && typeof tickLower === "number" && feeAmount) {
      const newPrice = tickToPrice(baseToken, quoteToken, tickLower + TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    if (!(typeof tickLower === "number") && baseToken && quoteToken && feeAmount && pool) {
      const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    return "";
  }, [baseToken, quoteToken, tickLower, feeAmount, pool]);
  const getDecrementUpper = useCallback(() => {
    if (baseToken && quoteToken && typeof tickUpper === "number" && feeAmount) {
      const newPrice = tickToPrice(baseToken, quoteToken, tickUpper - TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    if (!(typeof tickUpper === "number") && baseToken && quoteToken && feeAmount && pool) {
      const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    return "";
  }, [baseToken, quoteToken, tickUpper, feeAmount, pool]);
  const getIncrementUpper = useCallback(() => {
    if (baseToken && quoteToken && typeof tickUpper === "number" && feeAmount) {
      const newPrice = tickToPrice(baseToken, quoteToken, tickUpper + TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    if (!(typeof tickUpper === "number") && baseToken && quoteToken && feeAmount && pool) {
      const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount]);
      return newPrice.toSignificant(5, void 0, Rounding.ROUND_UP);
    }
    return "";
  }, [baseToken, quoteToken, tickUpper, feeAmount, pool]);
  const getSetFullRange = useCallback(() => {
    dispatch(setFullRange());
  }, [dispatch]);
  return { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange };
}
