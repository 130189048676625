"use strict";
import { useAccount } from "hooks/useAccount";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { addListener, removeListener } from "state/logs/slice";
import { filterToKey, isHistoricalLog } from "state/logs/utils";
var LogsState = /* @__PURE__ */ ((LogsState2) => {
  LogsState2[LogsState2["INVALID"] = 0] = "INVALID";
  LogsState2[LogsState2["LOADING"] = 1] = "LOADING";
  LogsState2[LogsState2["SYNCING"] = 2] = "SYNCING";
  LogsState2[LogsState2["ERROR"] = 3] = "ERROR";
  LogsState2[LogsState2["SYNCED"] = 4] = "SYNCED";
  return LogsState2;
})(LogsState || {});
export function useLogs(filter) {
  const { chainId } = useAccount();
  const blockNumber = useBlockNumber();
  const logs = useAppSelector((state) => state.logs);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!filter || !chainId) {
      return void 0;
    }
    dispatch(addListener({ chainId, filter }));
    return () => {
      dispatch(removeListener({ chainId, filter }));
    };
  }, [chainId, dispatch, filter]);
  return useMemo(() => {
    if (!chainId || !filter || !blockNumber) {
      return {
        logs: void 0,
        state: 0 /* INVALID */
      };
    }
    const state = logs[chainId]?.[filterToKey(filter)];
    const result = state?.results;
    if (!result) {
      return {
        state: 1 /* LOADING */,
        logs: void 0
      };
    }
    if (result.error) {
      return {
        state: 3 /* ERROR */,
        logs: void 0
      };
    }
    return {
      // if we're only fetching logs until a block that has already elapsed, we're synced regardless of result.blockNumber
      state: isHistoricalLog(filter, blockNumber) ? 4 /* SYNCED */ : result.blockNumber >= blockNumber ? 4 /* SYNCED */ : 2 /* SYNCING */,
      logs: result.logs
    };
  }, [blockNumber, chainId, filter, logs]);
}
