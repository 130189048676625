"use strict";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import { useEthersSigner } from "hooks/useEthersSigner";
import { useShowSwapNetworkNotification } from "hooks/useShowSwapNetworkNotification";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveSmartPool } from "state/application/hooks";
import { UniswapProvider } from "uniswap/src/contexts/UniswapContext";
import { AccountType } from "uniswap/src/features/accounts/types";
function useWebProvider(chainId) {
  return useEthersProvider({ chainId });
}
function useWagmiAccount() {
  const account = useAccount();
  const activeSmartPool = useActiveSmartPool();
  return useMemo(() => {
    if (!activeSmartPool?.address || !account.address) {
      return void 0;
    }
    return {
      address: activeSmartPool?.address ?? account.address,
      type: AccountType.SignerMnemonic
    };
  }, [activeSmartPool?.address, account.address]);
}
export function WebUniswapProvider({ children }) {
  const account = useWagmiAccount();
  const signer = useEthersSigner();
  const { connector } = useAccount();
  const showSwapNetworkNotification = useShowSwapNetworkNotification();
  const navigate = useNavigate();
  const navigateToFiatOnRamp = useCallback(() => navigate(`/buy`, { replace: true }), [navigate]);
  const accountDrawer = useAccountDrawer();
  return <UniswapProvider
    account={account}
    signer={signer}
    connector={connector}
    useProviderHook={useWebProvider}
    onSwapChainsChanged={showSwapNetworkNotification}
    navigateToFiatOnRamp={navigateToFiatOnRamp}
    onConnectWallet={accountDrawer.open}
  >{children}</UniswapProvider>;
}
