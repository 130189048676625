import { logErrorToDatadog, logToDatadog, logWarningToDatadog } from "utilities/src/logger/Datadog";
import { Sentry } from "utilities/src/logger/Sentry";
import { isInterface, isWeb } from "utilities/src/platform";
const SENTRY_CHAR_LIMIT = 8192;
let walletDatadogEnabled = false;
export const logger = {
  debug: (fileName, functionName, message, ...args) => logMessage("debug", fileName, functionName, message, ...args),
  info: (fileName, functionName, message, ...args) => logMessage("info", fileName, functionName, message, ...args),
  warn: (fileName, functionName, message, ...args) => logMessage("warn", fileName, functionName, message, ...args),
  error: (error, captureContext) => logException(error, captureContext),
  setWalletDatadogEnabled: (enabled) => {
    walletDatadogEnabled = enabled;
  }
};
function logMessage(level, fileName, functionName, message, ...args) {
  if (__DEV__ || isInterface) {
    console[level](...formatMessage(level, fileName, functionName, message), ...args);
  }
  if (__DEV__) {
    return;
  }
  if (level === "warn") {
    Sentry.captureMessage("warning", `${fileName}#${functionName}`, message, ...args);
    if (walletDatadogEnabled) {
      logWarningToDatadog(message, {
        level,
        args,
        functionName,
        fileName
      });
    }
  } else if (level === "info") {
    Sentry.captureMessage("info", `${fileName}#${functionName}`, message, ...args);
    if (walletDatadogEnabled) {
      logToDatadog(message, {
        level,
        args,
        functionName,
        fileName
      });
    }
  }
  if (isInterface) {
    logToDatadog(message, {
      level,
      args,
      functionName,
      fileName
    });
  }
}
function logException(error, captureContext) {
  const updatedContext = addErrorExtras(error, captureContext);
  if (__DEV__ || isInterface) {
    console.error(error);
  }
  if (__DEV__) {
    return;
  }
  for (const contextProp of Object.keys(updatedContext.tags)) {
    const prop = contextProp;
    const contextValue = updatedContext.tags[prop];
    if (typeof contextValue === "string") {
      updatedContext.tags[prop] = contextValue.slice(0, SENTRY_CHAR_LIMIT);
    }
  }
  Sentry.captureException(error, updatedContext);
  if (isInterface || walletDatadogEnabled) {
    logErrorToDatadog(error instanceof Error ? error : new Error(`${error}`), updatedContext);
  }
}
export function addErrorExtras(error, captureContext) {
  if (error instanceof Error) {
    const updatedContext = { ...captureContext };
    const extras = {};
    const { nativeStackAndroid, userInfo } = error;
    if (Array.isArray(nativeStackAndroid) && nativeStackAndroid.length > 0) {
      extras.nativeStackAndroid = nativeStackAndroid;
    }
    if (userInfo) {
      extras.userInfo = userInfo;
    }
    updatedContext.extra = { ...updatedContext.extra, ...extras };
    if (doesOverrideFingerprint(error)) {
      updatedContext.fingerprint = ["{{ default }}", ...error.getFingerprint()];
    }
    return updatedContext;
  }
  return captureContext;
}
function doesOverrideFingerprint(error) {
  return typeof error === "object" && error !== null && typeof error.getFingerprint === "function";
}
function pad(n, amount = 2) {
  return n.toString().padStart(amount, "0");
}
function formatMessage(level, fileName, functionName, message) {
  const t = /* @__PURE__ */ new Date();
  const timeString = `${pad(t.getHours())}:${pad(t.getMinutes())}:${pad(t.getSeconds())}.${pad(t.getMilliseconds(), 3)}`;
  if (isWeb) {
    return [
      level.toUpperCase(),
      {
        context: {
          time: timeString,
          fileName,
          functionName
        }
      },
      message
    ];
  } else {
    return [`${timeString}::${fileName}#${functionName}`, message];
  }
}
