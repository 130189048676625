"use strict";
import { FeeTierSearchModal } from "components/Liquidity/FeeTierSearchModal";
import {
  CreatePositionContext,
  CreateTxContext,
  DEFAULT_DEPOSIT_STATE,
  DEFAULT_PRICE_RANGE_STATE,
  DepositContext,
  PriceRangeContext,
  useCreatePositionContext,
  useDepositContext,
  usePriceRangeContext
} from "pages/Pool/Positions/create/CreatePositionContext";
import {
  useDerivedDepositInfo,
  useDerivedPositionInfo,
  useDerivedPriceRangeInfo
} from "pages/Pool/Positions/create/hooks";
import {
  DEFAULT_POSITION_STATE,
  PositionFlowStep
} from "pages/Pool/Positions/create/types";
import {
  generateAddLiquidityApprovalParams,
  generateCreateCalldataQueryParams,
  generateCreatePositionTxRequest
} from "pages/Pool/Positions/create/utils";
import { useMemo, useState } from "react";
import { useAccountMeta } from "uniswap/src/contexts/UniswapContext";
import { useCheckLpApprovalQuery } from "uniswap/src/data/apiClients/tradingApi/useCheckLpApprovalQuery";
import { useCreateLpPositionCalldataQuery } from "uniswap/src/data/apiClients/tradingApi/useCreateLpPositionCalldataQuery";
import { ONE_SECOND_MS } from "utilities/src/time/time";
export function CreatePositionContextProvider({
  children,
  initialState = {}
}) {
  const [positionState, setPositionState] = useState({ ...DEFAULT_POSITION_STATE, ...initialState });
  const [step, setStep] = useState(PositionFlowStep.SELECT_TOKENS_AND_FEE_TIER);
  const derivedPositionInfo = useDerivedPositionInfo(positionState);
  const [feeTierSearchModalOpen, setFeeTierSearchModalOpen] = useState(false);
  const [createPoolInfoDismissed, setCreatePoolInfoDismissed] = useState(false);
  return <CreatePositionContext.Provider
    value={{
      step,
      setStep,
      positionState,
      setPositionState,
      derivedPositionInfo,
      feeTierSearchModalOpen,
      setFeeTierSearchModalOpen,
      createPoolInfoDismissed,
      setCreatePoolInfoDismissed
    }}
  >
    {children}
    <FeeTierSearchModal />
  </CreatePositionContext.Provider>;
}
export function PriceRangeContextProvider({ children }) {
  const [priceRangeState, setPriceRangeState] = useState(DEFAULT_PRICE_RANGE_STATE);
  const derivedPriceRangeInfo = useDerivedPriceRangeInfo(priceRangeState);
  return <PriceRangeContext.Provider value={{ priceRangeState, setPriceRangeState, derivedPriceRangeInfo }}>{children}</PriceRangeContext.Provider>;
}
export function DepositContextProvider({ children }) {
  const [depositState, setDepositState] = useState(DEFAULT_DEPOSIT_STATE);
  const derivedDepositInfo = useDerivedDepositInfo(depositState);
  return <DepositContext.Provider value={{ depositState, setDepositState, derivedDepositInfo }}>{children}</DepositContext.Provider>;
}
export function CreateTxContextProvider({ children }) {
  const account = useAccountMeta();
  const { derivedPositionInfo, positionState } = useCreatePositionContext();
  const { derivedDepositInfo } = useDepositContext();
  const { priceRangeState, derivedPriceRangeInfo } = usePriceRangeContext();
  const addLiquidityApprovalParams = useMemo(() => {
    return generateAddLiquidityApprovalParams({
      account,
      positionState,
      derivedPositionInfo,
      derivedDepositInfo
    });
  }, [account, derivedDepositInfo, derivedPositionInfo, positionState]);
  const { data: approvalCalldata } = useCheckLpApprovalQuery({
    params: addLiquidityApprovalParams,
    staleTime: 5 * ONE_SECOND_MS
  });
  const createCalldataQueryParams = useMemo(() => {
    return generateCreateCalldataQueryParams({
      account,
      approvalCalldata,
      positionState,
      derivedPositionInfo,
      priceRangeState,
      derivedPriceRangeInfo,
      derivedDepositInfo
    });
  }, [
    account,
    approvalCalldata,
    derivedDepositInfo,
    derivedPositionInfo,
    derivedPriceRangeInfo,
    positionState,
    priceRangeState
  ]);
  const { data: createCalldata } = useCreateLpPositionCalldataQuery({
    params: createCalldataQueryParams,
    staleTime: 5 * ONE_SECOND_MS
  });
  const validatedValue = useMemo(() => {
    return generateCreatePositionTxRequest({
      approvalCalldata,
      createCalldata,
      createCalldataQueryParams,
      derivedPositionInfo,
      derivedDepositInfo
    });
  }, [approvalCalldata, createCalldata, createCalldataQueryParams, derivedPositionInfo, derivedDepositInfo]);
  return <CreateTxContext.Provider value={validatedValue}>{children}</CreateTxContext.Provider>;
}
