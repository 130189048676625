"use strict";
import { useSupportedChainId } from "constants/chains";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { COMMON_BASES } from "uniswap/src/constants/routing";
import { UniverseChainId } from "uniswap/src/types/chains";
import { useCurrencyInfo as useUniswapCurrencyInfo } from "uniswap/src/features/tokens/useCurrencyInfo";
import { buildCurrencyId } from "uniswap/src/utils/currencyId";
import { isAddress, isSameAddress } from "utilities/src/addresses";
export function useCurrency(address, chainId, skip) {
  const currencyInfo = useCurrencyInfo(address, chainId, skip);
  return currencyInfo?.currency;
}
export function useCurrencyInfo(addressOrCurrency, chainId, skip) {
  const { chainId: connectedChainId } = useAccount();
  const chainIdWithFallback = (typeof addressOrCurrency === "string" ? chainId : addressOrCurrency?.chainId) ?? connectedChainId;
  const nativeAddressWithFallback = UNIVERSE_CHAIN_INFO[chainIdWithFallback]?.nativeCurrency.address ?? UNIVERSE_CHAIN_INFO[UniverseChainId.Mainnet]?.nativeCurrency.address;
  const isNative = useMemo(() => checkIsNative(addressOrCurrency), [addressOrCurrency]);
  const address = useMemo(
    () => getAddress(isNative, nativeAddressWithFallback, addressOrCurrency),
    [isNative, nativeAddressWithFallback, addressOrCurrency]
  );
  const supportedChainId = useSupportedChainId(chainIdWithFallback);
  const addressWithFallback = isNative || !address ? nativeAddressWithFallback : address;
  const currencyId = buildCurrencyId(supportedChainId ?? UniverseChainId.Mainnet, addressWithFallback);
  const currencyInfo = useUniswapCurrencyInfo(currencyId, { skip });
  return useMemo(() => {
    const commonBase = getCommonBase(chainIdWithFallback, isNative, address);
    if (commonBase) {
      if (currencyInfo?.logoUrl) {
        commonBase.logoUrl = currencyInfo.logoUrl;
      }
      return commonBase;
    }
    if (!currencyInfo || !addressOrCurrency || skip) {
      return void 0;
    }
    return currencyInfo;
  }, [addressOrCurrency, currencyInfo, chainIdWithFallback, isNative, address, skip]);
}
const checkIsNative = (addressOrCurrency) => {
  return typeof addressOrCurrency === "string" ? [NATIVE_CHAIN_ID, "native", "eth"].includes(addressOrCurrency.toLowerCase()) : addressOrCurrency?.isNative ?? false;
};
const getCommonBase = (chainId, isNative, address) => {
  if (!address || !chainId) {
    return void 0;
  }
  return COMMON_BASES[chainId]?.find(
    (base) => base.currency.isNative && isNative || base.currency.isToken && isSameAddress(base.currency.address, address)
  );
};
const getAddress = (isNative, nativeAddressWithFallback, addressOrCurrency) => {
  if (typeof addressOrCurrency === "string") {
    if (isNative) {
      return nativeAddressWithFallback;
    } else {
      return addressOrCurrency;
    }
  }
  if (addressOrCurrency) {
    if (addressOrCurrency.isNative) {
      return nativeAddressWithFallback;
    } else if (addressOrCurrency) {
      return addressOrCurrency.address;
    }
  }
  return void 0;
};
export function useToken(tokenAddress, chainId) {
  const formattedAddress = isAddress(tokenAddress);
  const { chainId: connectedChainId } = useAccount();
  const currency = useCurrency(formattedAddress ? formattedAddress : void 0, chainId ?? connectedChainId);
  return useMemo(() => {
    if (currency && currency.isToken) {
      return currency;
    }
    return void 0;
  }, [currency]);
}
