"use strict";
import { CurrencyRow } from "components/SearchModal//CurrencyList";
import { CurrencySearch } from "components/SearchModal/CurrencySearch";
import TokenSafety from "components/TokenSafety";
import useLast from "hooks/useLast";
import styled from "lib/styled-components";
import { memo, useCallback, useEffect, useState } from "react";
import { useUserAddedTokens } from "state/user/userAddedTokens";
import { AdaptiveWebModal } from "ui/src";
import { TOKEN_SELECTOR_WEB_MAX_WIDTH } from "uniswap/src/components/TokenSelector/TokenSelector";
import { INTERFACE_NAV_HEIGHT } from "uniswap/src/theme/heights";
import { CurrencyField } from "uniswap/src/types/currency";
const PoolListWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const PoolListContainer = styled.div`
  width: 100%;
  padding: 32px 25px;
  display: flex;
  flex-flow: column;
  align-items: left;
`;
var CurrencyModalView = /* @__PURE__ */ ((CurrencyModalView2) => {
  CurrencyModalView2[CurrencyModalView2["search"] = 0] = "search";
  CurrencyModalView2[CurrencyModalView2["importToken"] = 1] = "importToken";
  CurrencyModalView2[CurrencyModalView2["tokenSafety"] = 2] = "tokenSafety";
  CurrencyModalView2[CurrencyModalView2["poolsList"] = 3] = "poolsList";
  return CurrencyModalView2;
})(CurrencyModalView || {});
export default memo(function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  currencyField = CurrencyField.INPUT,
  operatedPools,
  shouldDisplayPoolsOnly
}) {
  const [modalView, setModalView] = useState(0 /* search */);
  const lastOpen = useLast(isOpen);
  const userAddedTokens = useUserAddedTokens();
  useEffect(() => {
    if (isOpen && !lastOpen) {
      if (!shouldDisplayPoolsOnly) {
        setModalView(0 /* search */);
      } else {
        setModalView(3 /* poolsList */);
      }
    }
  }, [isOpen, lastOpen, shouldDisplayPoolsOnly]);
  const showTokenSafetySpeedbump = (token) => {
    if (token.symbol !== "GRG") {
      setWarningToken(token);
      setModalView(2 /* tokenSafety */);
    }
  };
  const handleCurrencySelect = useCallback(
    (currency, hasWarning) => {
      if (!shouldDisplayPoolsOnly && hasWarning && currency.isToken && !userAddedTokens.find((token) => token.equals(currency))) {
        showTokenSafetySpeedbump(currency);
      } else {
        onCurrencySelect(currency);
        onDismiss();
      }
    },
    [onDismiss, onCurrencySelect, userAddedTokens, shouldDisplayPoolsOnly]
  );
  const [warningToken, setWarningToken] = useState();
  let content = null;
  switch (modalView) {
    case 0 /* search */:
      content = <CurrencySearch
        currencyField={currencyField}
        onCurrencySelect={onCurrencySelect}
        onDismiss={onDismiss}
      />;
      break;
    case 3 /* poolsList */:
      content = <PoolListWrapper><PoolListContainer>{operatedPools?.map((pool) => <CurrencyRow
        key={pool.address}
        currency={pool}
        onSelect={() => {
          onCurrencySelect(pool);
        }}
        isSelected={false}
        balance={void 0}
        isSmartPool={true}
        eventProperties={{}}
        otherSelected={false}
      />)}</PoolListContainer></PoolListWrapper>;
      break;
    case 2 /* tokenSafety */:
      if (warningToken) {
        content = <TokenSafety
          token0={warningToken}
          onAcknowledge={() => handleCurrencySelect(warningToken)}
          closeModalOnly={() => setModalView(0 /* search */)}
          showCancel={true}
        />;
      }
      break;
  }
  return <AdaptiveWebModal
    isOpen={isOpen}
    onClose={onDismiss}
    maxHeight={modalView === 2 /* tokenSafety */ ? 400 : 700}
    maxWidth={TOKEN_SELECTOR_WEB_MAX_WIDTH}
    px={0}
    py={0}
    flex={1}
    $sm={{ height: `calc(100dvh - ${INTERFACE_NAV_HEIGHT}px)` }}
  >{content}</AdaptiveWebModal>;
});
