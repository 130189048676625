"use strict";
import { V2_ROUTER_ADDRESSES } from "@uniswap/sdk-core";
import ms from "ms";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { GQL_MAINNET_CHAINS, GQL_TESTNET_CHAINS, UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { useFeatureFlaggedChainIds } from "uniswap/src/features/chains/utils";
import { ArbitrumXV2ExperimentGroup, Experiments } from "uniswap/src/features/gating/experiments";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useExperimentGroupName, useFeatureFlag } from "uniswap/src/features/gating/hooks";
import { COMBINED_CHAIN_IDS, UniverseChainId } from "uniswap/src/types/chains";
export const AVERAGE_L1_BLOCK_TIME = ms(`12s`);
export function isSupportedChainId(chainId) {
  return !!chainId && COMBINED_CHAIN_IDS.includes(chainId);
}
export function useIsSupportedChainId(chainId) {
  const featureFlaggedChains = useFeatureFlaggedChainIds();
  const chainIsNotEnabled = !featureFlaggedChains.includes(chainId);
  return chainIsNotEnabled ? false : isSupportedChainId(chainId);
}
export function useIsSupportedChainIdCallback() {
  const featureFlaggedChains = useFeatureFlaggedChainIds();
  return useCallback(
    (chainId) => {
      const chainIsNotEnabled = !featureFlaggedChains.includes(chainId);
      return chainIsNotEnabled ? false : isSupportedChainId(chainId);
    },
    [featureFlaggedChains]
  );
}
export function useSupportedChainId(chainId) {
  const featureFlaggedChains = useFeatureFlaggedChainIds();
  if (!chainId || COMBINED_CHAIN_IDS.indexOf(chainId) === -1) {
    return void 0;
  }
  const chainDisabled = !featureFlaggedChains.includes(chainId);
  return chainDisabled ? void 0 : chainId;
}
export const isChainUrlParam = (str) => !!str && Object.values(UNIVERSE_CHAIN_INFO).some((chain) => chain.urlParam === str);
export const getChainUrlParam = (str) => isChainUrlParam(str) ? str : void 0;
export function getChain({
  chainId,
  withFallback
}) {
  return chainId ? UNIVERSE_CHAIN_INFO[chainId] : withFallback ? UNIVERSE_CHAIN_INFO[UniverseChainId.Mainnet] : void 0;
}
export const CHAIN_IDS_TO_NAMES = Object.fromEntries(
  Object.entries(UNIVERSE_CHAIN_INFO).map(([key, value]) => [key, value.interfaceName])
);
export const UX_SUPPORTED_GQL_CHAINS = [...GQL_MAINNET_CHAINS, ...GQL_TESTNET_CHAINS];
export const CHAIN_ID_TO_BACKEND_NAME = Object.fromEntries(
  Object.entries(UNIVERSE_CHAIN_INFO).map(([key, value]) => [key, value.backendChain.chain])
);
export function chainIdToBackendChain({
  chainId,
  withFallback
}) {
  return chainId ? CHAIN_ID_TO_BACKEND_NAME[chainId] : withFallback ? CHAIN_ID_TO_BACKEND_NAME[UniverseChainId.Mainnet] : void 0;
}
export const CHAIN_NAME_TO_CHAIN_ID = Object.fromEntries(
  Object.entries(UNIVERSE_CHAIN_INFO).filter(([, value]) => !value.backendChain.isSecondaryChain).map(([key, value]) => [value.backendChain.chain, parseInt(key)])
);
export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = Object.keys(UNIVERSE_CHAIN_INFO).filter((key) => UNIVERSE_CHAIN_INFO[parseInt(key)].supportsGasEstimates).map((key) => parseInt(key));
export const PRODUCTION_CHAIN_IDS = Object.values(UNIVERSE_CHAIN_INFO).filter((chain) => !chain.testnet).map((chain) => chain.id);
export const TESTNET_CHAIN_IDS = Object.keys(UNIVERSE_CHAIN_INFO).filter((key) => UNIVERSE_CHAIN_INFO[parseInt(key)].testnet).map((key) => parseInt(key));
export const SUPPORTED_V2POOL_CHAIN_IDS = Object.keys(V2_ROUTER_ADDRESSES).map((chainId) => parseInt(chainId));
export const BACKEND_SUPPORTED_CHAINS = Object.keys(UNIVERSE_CHAIN_INFO).filter((key) => {
  const chainId = parseInt(key);
  return UNIVERSE_CHAIN_INFO[chainId].backendChain.backendSupported && !UNIVERSE_CHAIN_INFO[chainId].backendChain.isSecondaryChain && !UNIVERSE_CHAIN_INFO[chainId].testnet;
}).map((key) => UNIVERSE_CHAIN_INFO[parseInt(key)].backendChain.chain);
export const BACKEND_NOT_YET_SUPPORTED_CHAIN_IDS = GQL_MAINNET_CHAINS.filter(
  (chain) => !BACKEND_SUPPORTED_CHAINS.includes(chain)
).map((chain) => CHAIN_NAME_TO_CHAIN_ID[chain]);
export const INFURA_PREFIX_TO_CHAIN_ID = Object.fromEntries(
  Object.entries(UNIVERSE_CHAIN_INFO).filter(([, value]) => !!value.infuraPrefix).map(([key, value]) => [value.infuraPrefix, parseInt(key)])
);
export function getChainPriority(chainId) {
  if (isSupportedChainId(chainId)) {
    return UNIVERSE_CHAIN_INFO[chainId].chainPriority;
  }
  return Infinity;
}
const isUniswapXEnabled = false;
export function useIsUniswapXSupportedChain(chainId) {
  const xv2ArbitrumEnabled = useExperimentGroupName(Experiments.ArbitrumXV2OpenOrders) === ArbitrumXV2ExperimentGroup.Test;
  const isPriorityOrdersEnabled = useFeatureFlag(FeatureFlags.UniswapXPriorityOrders);
  return isUniswapXEnabled && (chainId === UniverseChainId.Mainnet || xv2ArbitrumEnabled && chainId === UniverseChainId.ArbitrumOne || isPriorityOrdersEnabled && chainId === UniverseChainId.Base);
}
export function isStablecoin(currency) {
  if (!currency) {
    return false;
  }
  return getChain({ chainId: currency.chainId }).stablecoins.some(
    (stablecoin) => stablecoin.equals(currency)
  );
}
export function getChainFromChainUrlParam(chainUrlParam) {
  return chainUrlParam !== void 0 ? Object.values(UNIVERSE_CHAIN_INFO).find((chain) => chainUrlParam === chain.urlParam) : void 0;
}
export function useChainFromUrlParam() {
  const chainName = useParams().chainName;
  const tab = useParams().tab;
  return getChainFromChainUrlParam(getChainUrlParam(chainName ?? tab));
}
